<template>
  <v-dialog
    v-model="modal"
    class="draft-list-modal"
    width="1000px">
    <v-card class="px-3 py-5">
      <v-data-table
        class="draft-list-table"
        :items="items"
        :headers="headers"
        :loading="loading"
        :options.sync="paginate"
        :server-items-length="paginate.total"
        :footer-props="dataTableFooter"
        @click:row="selectDraft($event)">
        <template v-slot:[`item.checkingStatus`]="{ item }">
          {{ item.checkingStatus.toUpperCase() }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ $dayjs(item.createdAt).format('DD MMM YYYY | HH:mm') }}
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ $dayjs(item.updatedAt).format('DD MMM YYYY | HH:mm') }}
        </template>
        <template v-slot:[`item.products`]="{ item }">
          {{ mapProductToShowFormat(item.products) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex">
            <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="tryToDeleteCheckingDraft(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import StockCheckingProvider from '@/resources/StockCheckingProvider'

const StockCheckingService = new StockCheckingProvider()

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Status', value: 'checkingStatus', width: '10%' },
        { text: 'Create Time', value: 'createdAt', width: '18%' },
        { text: 'Create By', value: 'createdBy.email', width: '15%' },
        { text: 'Products', value: 'products', width: '50%' },
        { text: '', value: 'action', width: '7%' }
      ],
      loading: false,
      paginate: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      },
      items: [],
      dataTableFooter: {
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }
    }
  },
  computed: {
    modal: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  watch: {
    modal (value) {
      if (value) {
        this.getDrafts()
      }
    },
    paginate: {
      handler () {
        this.getDrafts()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    async getDrafts () {
      try {
        this.loading = true
        const { data } = await StockCheckingService.getStockCheckingDraft(this.paginate)

        this.items = data.results
        this.paginate.total = data.total
        this.paginate.pages = data.pages
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    mapProductToShowFormat (raws) {
      return raws.map((raw) => raw.skus.map((sku) => `${sku.code} (${sku.amount})`).join(', ')).join(', ')
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    selectDraft (item) {
      this.$emit('select:draft', item)
    },
    tryToDeleteCheckingDraft (id) {
      this.setModal({
        value: true,
        title: 'Remove Draft',
        message: 'Do you want to remove this Draft?',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => {
          this.deleteStockCheckingDraft(id)
        }
      })
    },
    async deleteStockCheckingDraft (id) {
      try {
        this.loading = true
        const { data } = await StockCheckingService.deleteStockCheckingDraft(id)

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Draft deleted',
            type: 'success'
          })

          this.getDrafts()
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[DELETE-DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.draft-list-table tr {
  cursor: pointer;
}

.draft-list-modal {
  z-index: 1000;
}
</style>
