import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StockCheckingProvider extends HttpRequest {
  createStockCheckingDraft (payload) {
    this.setHeader(getAuthToken())
    return this.post('stock-checking', payload)
  }

  getStockCheckingDraft (options) {
    this.setHeader(getAuthToken())
    return this.getByPagination('stock-checking', options)
  }

  getStockCheckingDraftById (id) {
    this.setHeader(getAuthToken())
    return this.get(`stock-checking/${id}`)
  }

  editStockCheckingDraftById (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`stock-checking/${id}`, payload)
  }

  deleteStockCheckingDraft (id) {
    this.setHeader(getAuthToken())
    return this.delete(`stock-checking/${id}`)
  }
}

export default StockCheckingProvider
