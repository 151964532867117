<template>
  <v-card class="product-checking-card">
    <v-row>
      <v-col cols="4">
        <gw-product-image
          :src="image"
          class="product-image-box" />
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-center">
        <div>
          <h3>{{ code }}</h3>
          <div class="d-flex">
            <p class="mr-3">
              {{ color }}
            </p>
            <p>{{ size }}</p>
          </div>
        </div>
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-center">
        <div class="text-center">
          <div class="d-flex align-center">
            <h1>{{ amount }}</h1>
            <v-btn
              icon
              x-large
              @click="decreaseAmount()">
              <v-icon>mdi-chevron-double-down</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-btn
      class="delete-btn"
      icon
      x-large
      @click="removeItem()">
      <h2>X</h2>
    </v-btn>
  </v-card>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      code () {
        return this.item?.code || ''
      },
      color () {
        return typeof this.item?.color === 'string' ? this.item?.color : this.item?.color?.name || '[NO COLOR]'
      },
      size () {
        return typeof this.item?.size === 'string' ? this.item?.size : this.item?.size?.name || '[NO SIZE]'
      },
      image () {
        return this.item?.images[0] || null
      },
      amount () {
        return this.item?.amount || 0
      }
    },
    methods: {
      decreaseAmount () {
        this.$emit('click:decrease')
      },
      removeItem () {
        this.$emit('click:remove')
      }
    }
  }
</script>

<style scoped>
.product-checking-card {
  position: relative;
  margin: 2px;
}
.delete-btn {
  z-index: 999;
  position: absolute;
  right: 0;
  top: 0;
}
/* .product-image-box.product-image{
  max-height: 134px;
}
.product-image-box.product-image .mock-image {
  max-height: 134px;
} */
</style>
