var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"draft-list-modal",attrs:{"width":"1000px"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',{staticClass:"px-3 py-5"},[_c('v-data-table',{staticClass:"draft-list-table",attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"options":_vm.paginate,"server-items-length":_vm.paginate.total,"footer-props":_vm.dataTableFooter},on:{"update:options":function($event){_vm.paginate=$event},"click:row":function($event){return _vm.selectDraft($event)}},scopedSlots:_vm._u([{key:"item.checkingStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.checkingStatus.toUpperCase())+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dayjs(item.createdAt).format('DD MMM YYYY | HH:mm'))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dayjs(item.updatedAt).format('DD MMM YYYY | HH:mm'))+" ")]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mapProductToShowFormat(item.products))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.tryToDeleteCheckingDraft(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }