import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ProductSkuProvider extends HttpRequest {
  getProductBySku (skuCode) {
    this.setHeader(getAuthToken())
    return this.get(`/stock-movement/${skuCode}`)
  }
}

export default ProductSkuProvider
