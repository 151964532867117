<template>
  <div>
    <v-card
      id="stock-checking"
      class="px-3 py-5">
      <v-row>
        <v-col
          md="6"
          sm="12"
          class="d-flex">
          <v-btn
            color="primary"
            @click="draftModalToggle(true)">
            <v-icon>mdi-format-list-bulleted</v-icon>
            DRAFT LIST
          </v-btn>
          <WarehouseSelect
            v-model="warehouseSelected"
            :items="warehouses"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            label="Warehouses"
            return-object
            outlined
            class="ml-4" />
        </v-col>
        <v-col
          md="6"
          sm="12"
          class="text-right">
          <v-btn
            v-if="draftId"
            color="error"
            class="mr-4"
            @click="cancelEditDraft()">
            CANCEL
          </v-btn>
          <v-btn
            color="success"
            @click="saveClicked()">
            <v-icon>mdi-content-save</v-icon>
            SAVE DRAFT
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="12"
          md="6"
          offset-md="3">
          <v-text-field
            v-model="search"
            label="SKU CODE"
            placeholder=" GMT001-1"
            append-icon="mdi-magnify"
            max-width="290px"
            hide-details
            outlined
            @paste.prevent
            @keypress="searchEnterPressed($event)"
            @click:append="searchClicked()" />
        </v-col>
      </v-row>
      <v-row
        v-for="(item, index) in items"
        :key="`product-item-${index}`">
        <v-col cols="12">
          <stock-checking-product-item
            :item="item"
            @click:decrease="decreaseAmountOfItem(index)"
            @click:remove="removeItemByIndex(index)" />
        </v-col>
      </v-row>
      <v-row v-if="hasItems">
        <v-col
          cols="8"
          class="mt-4 text-center">
          <h1 class="font-weight-bold">
            สรุปจำนวน <span class="text-weight-black">{{ skusNumber }}</span> แบบ <span class="text-weight-bold">{{ total }}</span> ชิ้น
          </h1>
        </v-col>
        <v-col
          cols="4"
          class="mt-4">
          <v-btn>
            <v-icon>mdi-printer</v-icon>
            PRINT
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="hasItems">
        <v-col class="d-flex justify-space-around mt-4">
          <v-btn
            x-large
            color="primary"
            @click="createDeliveryNotes()">
            สร้างใบนำเข้า
          </v-btn>
          <v-btn
            x-large
            color="primary"
            @click="createWithdrawNotes()">
            สร้างใบนำออก
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!hasItems">
        <v-col class="d-flex justify-center pt-16 mt-16">
          SCAN QR CODE OR ENTER SKU CODE
        </v-col>
      </v-row>
    </v-card>

    <stock-checking-draft-list
      v-model="modalListActive"
      @select:draft="draftSelected($event)" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ProductSkuProvider from '@/resources/ProductSkuProvider'
import ProductProvider from '@/resources/ProductProvider'
import StockCheckingProvider from '@/resources/StockCheckingProvider'
import InventoryProvider from '@/resources/InventoryProvider'

import WarehouseSelect from '@/components/WarehouseSelect.vue'
import StockCheckingProductItem from '../components/StockCheckingProductItem.vue'
import StockCheckingDraftList from '../components/StockCheckingDraftList.vue'

const ProductService = new ProductProvider()
const ProductSkuService = new ProductSkuProvider()
const StockCheckingService = new StockCheckingProvider()
const InventoryService = new InventoryProvider()

export default {
  components: {
    WarehouseSelect,
    StockCheckingProductItem,
    StockCheckingDraftList
  },
  data () {
    return {
      search: '',
      items: [],
      gettingSku: false,
      modalListActive: false,
      draft: null,
      warehouseSelected: null
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      storeSetting: 'Store/storeSetting'
    }),
    warehouses () {
      return this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
    },
    hasItems () {
      return this.items.length > 0
    },
    skusNumber () {
      return this.items.length
    },
    total () {
      return this.items.reduce(((total, acc) => total + acc.amount), 0)
    },
    draftId () {
      const { id } = this.$route.params

      return id
    }
  },
  watch: {
    draftId (value) {
      if (value) {
        this.getStockCheckingDraftById(value)
      }
    }
  },
  mounted () {
    if (this.draftId) {
      this.getStockCheckingDraftById(this.draftId)
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    searchClicked () {
      this.getProductSku()
    },
    searchEnterPressed (event) {
      const { charCode } = event

      if (charCode === 13) {
        this.getProductSku()
      }
    },
    async getProductById (productId) {
      const { data } = await ProductService.getProductById(productId)

      return data
    },
    saveClicked () {
      if (this.draftId) {
        this.editStockCheckingDraft()
      }

      if (!this.draftId) {
        this.saveStockCheckingDraft()
      }
    },
    async editStockCheckingDraft () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'SAVING DRAFT ...'
        })
        const { data } = await StockCheckingService.editStockCheckingDraftById(
          this.draftId,
          {
            ...this.draft,
            warehouse: this.warehouseSelected || this.draft.warehouse,
            products: await this.mapProductsForStockCheckingDraftFormat(this.items)
          }
        )

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Draft saved',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('SAVING DRAFT ERROR', error)
        this.setSnackbar({
          value: true,
          message: `[SAVING-DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.search = ''
      }
    },
    async mapProductsForStockCheckingDraftFormat (skus) {
      const productIds = [...new Set(skus.map((sku) => sku.productId))]
      const products = await Promise.all(productIds.map((productId) => this.getProductById(productId)))
      const results = productIds.map((productId) => {
        const currentSkus = skus.filter((sku) => sku.productId === productId)
        const thisProduct = products.find((product) => product.id === productId)

        return {
          id: productId,
          name: thisProduct.name,
          photoUrl: thisProduct.photoUrls[0] || '',
          variant: thisProduct.variant || '',
          model: thisProduct.model,
          skus: currentSkus.map((currentSku) => ({
            ...currentSku,
            color: currentSku.color?.name || '',
            size: currentSku.size?.name || '',
            images: currentSku.images
          }))
        }
      })

      return results
    },
    splitSkusFromProducts (products) {
      let results = []

      for (const product of products) {
        results = [...results, ...product.skus.map((sku) => ({
          ...sku,
          productId: product.id,
          color: { name: sku.color },
          size: { name: sku.size }
        }))]
      }

      return results
    },
    async getStockCheckingDraftById (id) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING DRAFT ...'
        })

        const { data } = await StockCheckingService.getStockCheckingDraftById(id)
        this.items = this.splitSkusFromProducts(data.products)
      } catch (error) {
        console.error('GETTING DRAFT ERROR', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING-DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.search = ''
      }
    },
    async saveStockCheckingDraft () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'SAVING DRAFT ...'
        })
        const { data } = await StockCheckingService.createStockCheckingDraft({
          warehouse: this.warehouseSelected,
          products: await this.mapProductsForStockCheckingDraftFormat(this.items)
        })

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Draft saved',
            type: 'success'
          })

          this.$router.push({ name: 'StockCheckingEdit', params: { id: data.id } })
        }
      } catch (error) {
        console.error('SAVING DRAFT ERROR', error)
        this.setSnackbar({
          value: true,
          message: `[SAVING-DRAFT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.search = ''
      }
    },
    removeUnnecessoriesText (text) {
      let result = text
      const forbidTexts = [`${this.storeSetting.webUrl}/`]

      forbidTexts.forEach((forbid) => {
        result = result.replace(forbid, '')
      })

      return result
    },
    async getProductSku () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING SKU ...'
        })

        const skuCode = this.removeUnnecessoriesText(this.search)
        const { data } = await ProductSkuService.getProductBySku(skuCode.toUpperCase())

        if (data) {
          this.addItemToList(data)
          this.setSnackbar({
            value: true,
            message: `[${skuCode}] has been add to list`,
            type: 'success'
          })
        }

        if (!data) {
          this.setSnackbar({
            value: true,
            message: `[${skuCode}] doesn't exist`,
            type: 'error'
          })
        }
      } catch (error) {
        console.error('GETTING SKU ERROR', error)
        this.setSnackbar({
          value: true,
          message: `[GET-SKU-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.search = ''
      }
    },
    addItemToList (newItem) {
      const existed = this.items.findIndex((item) => item.code === newItem.code)
      if (existed !== -1) {
        this.items[existed].amount++
      }

      if (existed === -1) {
        this.items.push({ ...newItem, amount: 1 })
      }
    },
    decreaseAmountOfItem (index) {
      if (this.items[index].amount > 1) {
        this.items[index].amount--
      } else if (this.items[index].amount <= 1) {
        this.removeItemByIndex(index)
      }
    },
    removeItemByIndex (index) {
      this.setModal({
        value: true,
        title: 'Remove Product',
        message: 'Do you want to remove this Product?',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => {
          this.items.splice(index, 1)
        }
      })
    },
    draftModalToggle (value) {
      this.modalListActive = value
    },
    draftSelected (draft) {
      this.draftModalToggle(false)
      this.$router.push({ name: 'StockCheckingEdit', params: { id: draft.id } })
    },
    cancelEditDraft () {
      this.draft = null
      this.items = []
      this.$router.push({ name: 'StockChecking' })
    },
    async createWithdrawNotes () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'CREATE WITHDRAW NOTE ...'
        })

        const { data } = await InventoryService.createWithdrawNotes({
          warehouse: this.warehouseSelected,
          products: await this.mapProductsForStockCheckingDraftFormat(this.items),
          note: 'Stock Checking',
          status: 'pending'
        })

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Withdraw note created',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('CREATE WITHDRAW NOTE ERROR', error)
        this.setSnackbar({
          value: true,
          message: `[CREATE-WITHDRAW-NOTE-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async createDeliveryNotes () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'CREATE DELIVERY NOTE ...'
        })

        const { data } = await InventoryService.createDeliveryNotes({
          warehouse: this.warehouseSelected,
          products: await this.mapProductsForStockCheckingDraftFormat(this.items),
          note: 'Stock Checking',
          status: 'pending'
        })

        if (data) {
          this.setSnackbar({
            value: true,
            message: 'Delivery note created',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('CREATE DELIVERY NOTE ERROR', error)
        this.setSnackbar({
          value: true,
          message: `[CREATE-DELIVERY-NOTE-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style scoped>
#stock-checking {
  min-height: calc(100vh - 68px);
}
</style>
